import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { FlexContainerResponsive, FlexContainerHorizontal, FlexItemMin, FlexItemMax, } from "../FlexContainer";
import { Box } from "../Box";
import { IsLoading } from "../IsLoading";
import { IconButton } from "../IconButton";
import { Typography } from "../Typography";
import { createIcon, } from "../IconComponent";
import { IconViewer } from "../IconViewer";
import BackIcon from '@mui/icons-material/ArrowBackIosNew';
import { getDataComponentName } from "../web-utils";
export const ContainerSection = (props) => {
    const { dataComponentName, sx, show = true, h = 1, fullHeight, isLoading = false, Icon, SubIcon, title, subtitle, description, topRightContainer, children, nestedChildren = true, onBackButtonClick, } = props;
    const factor = 5 + 1 - h;
    if (!show)
        return null;
    const applyChildren = (_jsx(IsLoading, { isLoading: isLoading, children: children }));
    return (_jsxs(_Fragment, { children: [_jsxs(FlexContainerResponsive, { dataComponentName: getDataComponentName(dataComponentName, 'ContainerSection'), sx: sx, verticalOnMobile: true, fullHeight: fullHeight, spacing: 1, children: [_jsxs(FlexItemMin, { sx: { position: 'relative' }, show: !!Icon, children: [_jsx(IconViewer, { Icon: Icon, width: factor * 24 }), _jsx(Box, { show: !!SubIcon, sx: {
                                    position: 'absolute',
                                    right: theme => theme.spacing(-1),
                                    bottom: 0,
                                    padding: theme => theme.spacing(factor / 5),
                                    backgroundColor: theme => theme.palette.background.paper,
                                    borderRadius: (factor * 12) + 'px',
                                    border: '1px solid',
                                    borderColor: theme => theme.palette.text.secondary,
                                    fontSize: 0,
                                }, children: _jsx(IconViewer, { Icon: SubIcon, width: factor * 5 }) })] }), _jsxs(FlexItemMax, { sx: { paddingTop: theme => theme.spacing(1) }, children: [_jsxs(FlexContainerHorizontal, { alignVertical: "top", children: [_jsxs(FlexItemMax, { children: [_jsxs(Typography, { v: `h${h}`, children: [_jsx(IconButton, { show: !!onBackButtonClick, ariaLabel: "Back", title: "Go back", Icon: createIcon.byMuiIcon(BackIcon, {
                                                            position: "relative",
                                                            left: -2,
                                                        }), onClick: onBackButtonClick }), title] }), _jsx(Typography, { v: `h${h + 1}`, children: subtitle }), _jsx(Typography, { v: "body1", children: description })] }), _jsx(FlexItemMin, { children: topRightContainer })] }), !!nestedChildren && applyChildren] })] }), !nestedChildren && (_jsx(Box, { dataComponentName: getDataComponentName(dataComponentName, 'ContainerSection'), children: applyChildren }))] }));
};
