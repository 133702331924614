import { getDefaultDBEntityDocument, } from "utils-library/dist/db-entity-interfaces";
import { EDeviceType } from "./EDeviceType";
export var EDeviceBrokerType;
(function (EDeviceBrokerType) {
    EDeviceBrokerType["UNKNOWN"] = "UNKNOWN";
    EDeviceBrokerType["MHC_MQTT"] = "MHC_MQTT";
    EDeviceBrokerType["OCTAVE"] = "OCTAVE";
})(EDeviceBrokerType || (EDeviceBrokerType = {}));
export const getDefaultDevice = () => getDefaultDBEntityDocument({
    order: -1,
    deviceType: EDeviceType.UNKNOWN,
    isActive: false,
    deviceBrokerId: '',
    deviceBrokerType: EDeviceBrokerType.UNKNOWN,
    regionId: '',
    locationId: '',
    activationId: '',
    manufacturerName: '',
    showOnMap: true,
    label: '',
    description: '',
    geoPosition: {
        lat: -1,
        lng: -1,
        alt: 0,
    },
    outdatedMsThreshold: 5000,
    deadMsThreshold: 60000,
    notes: '',
});
