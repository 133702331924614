var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useState, useEffect, } from "react";
import { dynaError, } from "dyna-error";
import { useIsMounted } from "../useIsMounted";
export const useClipboardCopy = ({ refContent, disabled, refButton, resetCopyTimeout = 3000, }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isCopied, setIsCopied] = useState(false);
    const [error, setError] = useState(null);
    const getIsMounted = useIsMounted();
    useEffect(() => {
        if (!refButton.current)
            return;
        let timeout;
        const handleClick = () => __awaiter(void 0, void 0, void 0, function* () {
            if (disabled)
                return;
            if (timeout) {
                clearTimeout(timeout);
                timeout = null;
            }
            try {
                setIsLoading(true);
                setError(null);
                if (!refContent.current)
                    throw new Error('Error: 202304281947: refCurrent.current is invalid');
                if (!refContent.current.innerText)
                    throw new Error('Error: 202304281948: Content to copy is empty');
                yield navigator.clipboard.writeText(refContent.current.innerText);
                if (!getIsMounted())
                    return;
                setIsCopied(true);
                timeout = setTimeout(() => {
                    timeout = null;
                    if (!getIsMounted())
                        return;
                    setIsCopied(false);
                }, resetCopyTimeout);
            }
            catch (e) {
                const error = dynaError(e);
                setError(error);
                console.error('Failed to copy: ', error);
            }
            finally {
                setIsLoading(false);
            }
        });
        refButton.current.addEventListener('click', handleClick);
        return () => {
            if (!refButton.current)
                return;
            if (!isClipboardSupported)
                return;
            refButton.current.removeEventListener('click', handleClick);
            timeout && clearTimeout(timeout);
        };
    }, [
        refContent,
        refContent === null || refContent === void 0 ? void 0 : refContent.current,
        refButton,
        refButton.current,
    ]);
    return {
        isLoading,
        isClipboardSupported,
        isCopied,
        error,
    };
};
export const isClipboardSupported = !!navigator.clipboard && typeof navigator.clipboard.writeText === 'function';
