import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useRef } from "react";
import { Box } from "../Box";
import { ContainerPortal } from "../ContainerPortal";
import { Button, EButtonColor, } from "../Button";
import { useClipboardCopy } from "../useClipboardCopy";
import { createIcon, } from "../IconComponent";
import CopyIcon from '@mui/icons-material/ContentCopy';
import CopiedIcon from '@mui/icons-material/CheckCircleOutline';
import IsLoadingIcon from '@mui/icons-material/Cached';
export const ButtonCopyToClipboard = (props) => {
    const { Icon = createIcon.byMuiIcon(CopyIcon), label = "Copy to clipboard", description, title, color, show, disabled, hideLabelOnMobile, resetCopyTimeout = 3000, children, } = props;
    const refButton = useRef(null);
    const refSource = useRef(null);
    const { isLoading, isClipboardSupported, isCopied, error, } = useClipboardCopy({
        refButton,
        disabled,
        refContent: refSource,
        resetCopyTimeout,
    });
    return (_jsxs(_Fragment, { children: [_jsx(Box, { inline: true, ref: refButton, children: _jsx(Button, { sx: { cursor: isClipboardSupported ? undefined : "not-allowed" }, show: show, Icon: isLoading
                        ? createIcon.byMuiIcon(IsLoadingIcon)
                        : isCopied
                            ? createIcon.byMuiIcon(CopiedIcon)
                            : Icon, description: [
                        description,
                        error && `Error: ${error.userMessage || error.message || 'Unknown error'}`,
                    ]
                        .filter(Boolean)
                        .join('\n'), title: isClipboardSupported ? title : 'Clipboard not supported by your browser', color: error
                        ? EButtonColor.ERROR
                        : isCopied
                            ? EButtonColor.SUCCESS
                            : color, disabled: isLoading ||
                        disabled ||
                        !isClipboardSupported, hideLabelOnMobile: hideLabelOnMobile, children: label }) }), _jsx(ContainerPortal, { children: _jsx("div", { hidden: true, ref: refSource, children: children }) })] }));
};
