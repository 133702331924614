import { jsx as _jsx } from "react/jsx-runtime";
import { IconViewer } from "../IconViewer";
/**
 * The createIcon method helps to create responsive icons based on SVG elements/images and MUI icons.
 * The result is a React component with the IIconComponent interface, which only has a few props like width and height.
 * The color is inherited.
 */
export const createIcon = {
    /**
     * Create an icon by an URL
     */
    byUrl: (url, sx) => createIcon.byImage(url, sx),
    /**
     * Create an icon by an imported from webpack image (jpg, gif, etc.)
     */
    byImage: (image, sx) => ({ width, height, }) => {
        return (_jsx(IconViewer, { sx: sx, image: image, width: width, height: height }));
    },
    /**
     * Create an icon by an SVG (as JSX) element image
     */
    bySVGElement: (svg, sx) => ({ width, height, }) => {
        return (_jsx(IconViewer, { sx: sx, svgElement: svg, width: width, height: height }));
    },
    /**
     * Create an icon by an imported from webpack SVG image or URL of it
     */
    bySVGImage: (image, sx) => ({ width, height, }) => {
        return (_jsx(IconViewer, { sx: sx, svgScript: image, width: width, height: height }));
    },
    /**
     * Create icon by MUI's package icons
     *
     * Browser icons: https://mui.com/material-ui/material-icons
     */
    byMuiIcon: (MuiIcon, sx) => ({ width, height, }) => {
        return (_jsx(IconViewer, { sx: sx, MuiIcon: MuiIcon, width: width, height: height }));
    },
    /**
     * Create icon by Iconify icons
     *
     * Iconify icons are a larger open-source collection than MUI's repository.
     *
     * Website: https://iconify.design
     *
     * Instructions:
     * - Explore icons: https://icon-sets.iconify.design
     * - Choose an icon
     * - In the components section, select the "Unplugin icons" option
     * - Copy the import script, for example: `import MdiFlight from '~icons/mdi/flight';`
     * - Replace the `~icons` in the import with `@iconify-icons`
     * - If the icons package not installed, you may need to run `pnpm add @iconify-icons/mdi` for the above import example.
     */
    byIconifyIcon: (iconifyIcon, sx) => ({ width, height, }) => {
        return (_jsx(IconViewer, { sx: sx, IconifyIcon: iconifyIcon, width: width, height: height }));
    },
    /**
     * Emoji icon
     *
     * Directly the UTF-8 sequence of the icon and not as a human string.
     *
     * You can pick emoji's from https://emojipedia.org
     *
     * @example Apply it like `🍔` NOT like `:hamburger:`
     */
    byEmoji: (emoji, sx) => ({ width }) => {
        return (_jsx(IconViewer, { sx: sx, emoji: emoji, width: width }));
    },
};
