import { validateDataMethods } from "utils-library/dist/validation-engine";
import { EDeviceType } from "../interfaces/EDeviceType";
import { EDeviceCycleStatus } from "../interfaces/IDeviceStatus";
export const ConnectITSLocationHub = {
    deviceType: EDeviceType.CONNECT_ITS_LOCATION_HUB,
    label: 'Connect ITS location hub',
    validateConfig: { maintenanceMode: validateDataMethods.isBoolean },
    validateStatus: {
        activationId: validateDataMethods.isString,
        manufacturerName: validateDataMethods.isString,
        firmwareVersion: validateDataMethods.isString,
        cycleStatus: value => validateDataMethods.isEnumValue(value, EDeviceCycleStatus),
        acPower: validateDataMethods.isBoolean,
        dcVoltage: validateDataMethods.isNumber,
        flasherStatus: validateDataMethods.isBoolean,
        batteryChargePercentage: value => validateDataMethods.isNumberInRange(value, 0, 100),
    },
};
export const getDefaultConnectITSLocationHubConfig = () => ({ maintenanceMode: false });
