import moment from 'moment-timezone';
import { isTimezoneValid } from "./isTimezoneValid";
/**
 * Calculates the difference in hours between two time zones at a given datetime.
 *
 * @returns {number} - The difference how many hours ahead or behind (negative number) the targetTimezone is compared to the sourceTimezone.
 *
 * @example
 * // Calculate the difference in hours between New York and London at a specific time
 * const diff = getTimezonesHoursDifference({
 *   datetime: '2023-05-23T12:00:00Z',
 *   sourceTimezone: 'America/New_York',
 *   targetTimezone: 'Europe/London'
 * });
 * console.log(diff); // Outputs: 5
 *
 * @example
 * // Calculate the difference in hours using the local time zone as the source time zone
 * const diff = getTimezonesHoursDifference({
 *   datetime: '2023-05-23T12:00:00Z',
 *   targetTimezone: 'Europe/London'
 * });
 * console.log(diff); // Outputs the difference based on the local time zone
 */
export const getTimezonesHoursDifference = ({ dateTime = new Date(), sourceTimezone = moment.tz.guess(), targetTimezone, }) => {
    if (!isTimezoneValid(sourceTimezone)) {
        console.error(`getTimezonesHoursDifference invalid sourceTimezone: [${sourceTimezone}], this is not ISO-8601. -603 is returned.`);
        return -603;
    }
    if (!isTimezoneValid(targetTimezone)) {
        console.error(`getTimezonesHoursDifference invalid targetTimezone: [${targetTimezone}], this is not ISO-8601. -604 is returned.`);
        return -604;
    }
    const momentDatetime = moment(dateTime);
    const sourceOffset = momentDatetime.tz(sourceTimezone).utcOffset();
    const targetOffset = momentDatetime.tz(targetTimezone).utcOffset();
    return (targetOffset - sourceOffset) / 60;
};
