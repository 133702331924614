var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useState, useEffect, } from "react";
import { dynaError, } from "dyna-error";
import { guid } from "dyna-guid";
import { ELoadState } from "../ui-interfaces";
import { useIsMounted } from "../useIsMounted";
import { useOnChange } from "../useOnChange";
export const useLoadMoreData = ({ loadOnStartup = true, debounceTimeout = 300, loadSize = 20, loadAll = false, reloadDep, errorHandling = {}, onLoad, }) => {
    const { consoleIt = true, consoleMessage = 'useLoadMoreData: Load error', userMessage, } = errorHandling;
    const getIsMounted = useIsMounted();
    const [loadArgs, setLoadArgs] = useState({
        reloadId: '',
        loadType: "reload",
    });
    const [loadState, setLoadState] = useState(ELoadState.INIT);
    const [isLoading, setIsLoading] = useState(loadOnStartup);
    const [hasMore, setHasMore] = useState(true);
    const [items, _setItems] = useState([]);
    const [error, setError] = useState(null);
    const [changeId, setChangeId] = useState("");
    const setItems = (items) => {
        _setItems(items);
        setChangeId(guid());
    };
    useOnChange({
        dep: reloadDep,
        onChange: () => reload(),
    });
    useEffect(() => {
        if (!loadArgs.reloadId)
            return;
        let ignore = false;
        (() => __awaiter(void 0, void 0, void 0, function* () {
            try {
                yield new Promise(r => setTimeout(r, debounceTimeout));
                if (ignore)
                    return;
                setLoadState(ELoadState.IS_LOADING);
                setIsLoading(true);
                const loadedItems = yield (() => {
                    switch (loadArgs.loadType) {
                        case "reload":
                            return onLoad({
                                skip: 0,
                                limit: loadSize,
                            });
                        case "refresh":
                            return onLoad({
                                skip: 0,
                                limit: items.length,
                            });
                        case "load-more":
                            return onLoad({
                                skip: items.length,
                                limit: loadSize,
                            });
                        default:
                            throw new Error(`Internal error 20231011165448: Unknown loadType [${loadArgs.loadType}]`); // 4TS, not possible to happen this
                    }
                })();
                if (!getIsMounted())
                    return;
                if (ignore)
                    return;
                if (loadArgs.loadType === "reload") {
                    setHasMore(loadedItems.length === loadSize);
                    setItems(loadedItems);
                }
                if (loadArgs.loadType === "refresh") {
                    setItems(loadedItems);
                }
                if (loadArgs.loadType === "load-more") {
                    setHasMore(loadedItems.length === loadSize);
                    setItems(items.concat(loadedItems));
                }
                if (error)
                    setError(null);
                if (loadAll && loadedItems.length === loadSize) {
                    loadMore();
                }
                else {
                    setLoadState(ELoadState.SUCCESS);
                    setIsLoading(false);
                }
            }
            catch (e) {
                const error = dynaError(e);
                if (!getIsMounted())
                    return;
                if (ignore)
                    return;
                if (userMessage)
                    error.userMessage = userMessage;
                if (consoleIt)
                    console.error(consoleMessage, error);
                setError(error);
                setLoadState(ELoadState.FAILED);
                setIsLoading(false);
            }
        }))();
        return () => {
            ignore = true;
        };
    }, [loadArgs.reloadId]);
    useEffect(() => {
        if (loadOnStartup)
            reload();
    }, []);
    const reload = () => {
        setLoadArgs({
            reloadId: guid(),
            loadType: "reload",
        });
    };
    const refresh = () => {
        setLoadArgs({
            reloadId: guid(),
            loadType: "refresh",
        });
    };
    const loadMore = () => {
        setLoadArgs({
            reloadId: guid(),
            loadType: "load-more",
        });
    };
    return {
        loadState,
        isLoading,
        hasMore,
        changeId,
        items,
        error,
        setItems,
        reload,
        refresh,
        loadMore,
    };
};
