/**
 * Get the Window or Process based on the running environment (Browser or Node).
 * This helps make utilities universal whenever possible.
 */
export const getGlobal = () => {
    if (typeof window !== "undefined")
        return window;
    if (typeof process !== "undefined")
        return process;
    throw Error("ConsoleOnce error 51965420898240: Unknown environment");
};
