var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as React from "react";
import { dynaDebounce } from "dyna-debounce";
import { deleteUndefinedProperties, areValuesEqual, } from "utils-library/dist/utils";
import { isLocalhost } from "utils-library/dist/web";
/**
 * `PerformanceComponent` is a class component that allows for massive and efficient state updates, resulting in smooth content rendering on the DOM.
 *
 * # Important note about the state
 * This component has two versions of states: the `actualState`,
 * which holds the most up-to-date state updated through the `setState`/`setDebouncedState` methods,
 * and the regular `state` inherited from React.
 * The latter represents what should be displayed on the DOM.
 * Please note that the `state` should be used **exclusively** for rendering purposes.
 * For all other use cases, you should retrieve the state from the `actualState` property.
 *
 * The lifecycle of all React's methods is exactly the same. https://projects.wojtekmaj.pl/react-lifecycle-methods-diagram/
 *
 * For more read the `PerformanceComponent.readme.md` document.
 */
export class PerformanceComponent extends React.Component {
    constructor(props, config = {
        debounceWait: 100,
        debounceMaxWait: 500,
        debounceLeading: true,
    }) {
        super(props);
        this.config = config;
        this._actualState = this.state;
        this._isMounted = false;
        this._stateUpdatesCount = 0;
        this._domUpdatesCount = 0;
        this._mountTimeoutHandler = null;
        /**
         * Classic vanilla setState method
         * @param state
         * @param callback
         * @param dontRender
         */
        // @ts-ignore
        // eslint-disable-next-line
        this.setState = (state, callback, { dontRender = false } = {}) => {
            this.updateState(typeof state === "function"
                ? state
                : () => state, {
                callback,
                dontRender,
            });
        };
        /**
         * Set state in a debounced way
         * @param state - The state to set
         * @param callback - The callback function to execute after debouncing
         */
        this.setDebouncedState = (state, callback) => {
            this.updateState(typeof state === "function"
                ? state
                : () => state, {
                callback,
                debounce: true,
            });
        };
        this.updateState = (updater_1, ...args_1) => __awaiter(this, [updater_1, ...args_1], void 0, function* (updater, { debounce = false, dontRender = false, callback, } = {}) {
            const userState = updater(this.actualState);
            if (!userState) {
                callback && callback(this.actualState);
                return;
            }
            this.actualState = Object.assign(Object.assign({}, this.actualState), deleteUndefinedProperties(userState));
            this._stateUpdatesCount++;
            if (dontRender)
                return;
            debounce
                ? this.applyStateDebounced(callback)
                : this.applyStateDirect(callback);
        });
        this.applyStateDirect = (callBack) => {
            if (!this.isMounted)
                return;
            if (areValuesEqual(this.state, this.actualState))
                return;
            super.setState(this.actualState, callBack ? () => callBack(this.actualState) : undefined);
            this._domUpdatesCount++;
        };
        this.applyStateDebounced = (callBack) => {
            this.applyStateDirect(callBack);
        };
        this.overrideComponentDidMount();
        this.overrideComponentWillUnmount();
        this.applyStateDebounced = dynaDebounce(this.applyStateDebounced, this.config.debounceWait, {
            maxWait: this.config.debounceMaxWait,
            leading: this.config.debounceLeading,
        });
    }
    overrideComponentDidMount() {
        const userComponentDidMount = this.componentDidMount;
        this.componentDidMount = () => {
            this._isMounted = true;
            this._mountTimeoutHandler = setTimeout(() => {
                this._mountTimeoutHandler = null;
                userComponentDidMount === null || userComponentDidMount === void 0 ? void 0 : userComponentDidMount.call(this);
            }, isLocalhost ? 20 : 0);
        };
    }
    overrideComponentWillUnmount() {
        const userComponentWillUnmount = this.componentWillUnmount;
        this.componentWillUnmount = () => {
            this._isMounted = false;
            if (this._mountTimeoutHandler !== null) {
                clearTimeout(this._mountTimeoutHandler);
                this._mountTimeoutHandler = null;
            }
            else
                userComponentWillUnmount === null || userComponentWillUnmount === void 0 ? void 0 : userComponentWillUnmount.call(this);
        };
    }
    /**
     * How many times the state of the component has been changed.
     */
    get stateUpdatesCount() {
        return this._stateUpdatesCount;
    }
    /**
     * How many times the React's state (so renders) has been applied.
     */
    get domUpdatesCount() {
        return this._domUpdatesCount;
    }
    /**
     * Get the actual (latest) state.
     * This version represents the most up-to-date state, but it may not have been rendered yet.
     * Use it always to update the state, but note that this is already offered by the setState & setDebouncedState methods.
     * Avoid using it for rendering or in event handlers. Instead, use the classic `this.state`.
     */
    get actualState() {
        if (!this._actualState)
            this._actualState = this.state;
        return this._actualState;
    }
    set actualState(state) {
        this._actualState = state;
    }
    get isMounted() {
        return this._isMounted;
    }
}
