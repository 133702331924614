export const enumInfo = (setupDic) => ({
    dicEnumInfo: Object.entries(setupDic)
        .reduce((acc, [enumKey, { label, description, }]) => {
        acc[enumKey] = {
            enum: enumKey,
            label,
            description,
        };
        return acc;
    }, {}),
    arrayEnumInfo: Object.entries(setupDic)
        .reduce((acc, [enumKey, { label, description, },]) => acc.concat({
        enum: enumKey,
        label,
        description,
    }), []),
    arrayValueLabelDescription: Object.entries(setupDic)
        .reduce((acc, [enumKey, { label, description, },]) => acc.concat({
        label: label.defaultMessage || label.id || "--er2322--",
        description: description.defaultMessage || description.id || "--er2323--",
        value: enumKey,
    }), []),
    translationKeys: Object.values(setupDic)
        .reduce((acc, { label: { id: labelId }, description: { id: descriptionId }, }) => {
        if (labelId)
            acc[labelId] = labelId;
        if (descriptionId)
            acc[descriptionId] = descriptionId;
        return acc;
    }, {}),
    arrayValueLabelDescriptionTranslated: translate => Object.entries(setupDic)
        .reduce((acc, [enumKey, { label, description, },]) => {
        return acc.concat({
            label: translate(label),
            description: translate(description),
            value: enumKey,
        });
    }, []),
});
