import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import * as ReactDOM from "react-dom";
import { createRef, } from "react";
import { PerformanceComponent } from "../PerformanceComponent";
import { watchElementRemoval } from "./utils/watchElementRemoval";
import { watchElementVisibility } from "./utils/watchElementVisibility";
/**
 * This container renders the children within a React portal, incorporating additional features
 * such as direct styling of the portal container and monitoring the visibility of the parent.
 */
export class ContainerPortal extends PerformanceComponent {
    constructor(props) {
        super(props);
        this.refContainer = createRef();
        this.state = {
            isParentInTheDom: true,
            isParentVisible: true,
        };
        const { nodeType = "div" } = props;
        this.refFloatContainer = document.createElement(nodeType);
    }
    componentDidMount() {
        const { parentElement = document.body } = this.props;
        parentElement.appendChild(this.refFloatContainer);
        if (this.refContainer.current) {
            watchElementRemoval({
                element: this.refContainer.current,
                onRemove: () => this.setState({ isParentInTheDom: false }),
            });
            watchElementVisibility({
                element: this.refContainer.current,
                onVisibilityChange: isVisible => {
                    if (this.state.isParentVisible !== isVisible)
                        this.setState({ isParentVisible: isVisible });
                },
            });
        }
        else {
            // Dev info: this.refContainer.current null???
            // This is happening only when the component is not on the DOM
            // There is nothing to do, just ignore it.
        }
    }
    componentDidUpdate() {
        this.applyStyle();
    }
    applyStyle() {
        const { style } = this.props;
        if (!style)
            return;
        Object.keys(style)
            .forEach((styleProp) => this.refFloatContainer.style[styleProp] = style[styleProp]);
    }
    get innerRef() {
        return this.refFloatContainer;
    }
    componentWillUnmount() {
        document.body.removeChild(this.refFloatContainer);
    }
    render() {
        const { show = true, detectVisibilityElementId, children, } = this.props;
        const { isParentInTheDom, isParentVisible, } = this.state;
        if (!show
            || !isParentInTheDom)
            return null;
        return (_jsxs(_Fragment, { children: [_jsx("div", { id: detectVisibilityElementId, ref: this.refContainer }), isParentVisible && (ReactDOM.createPortal(children, this.refFloatContainer))] }));
    }
}
