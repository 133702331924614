var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/**
 * Sets up a listener to handle the 'SIGINT' signal and gracefully exit the Node.js process.
 *
 * @param {() => Promise<number>} onExit - An asynchronous callback that returns the exit code, 0 for success.
 * If an error occurs within this callback, the exit code defaults to 500.
 */
export const nodeAppOnExit = (onExit) => {
    process.on('SIGINT', () => {
        (() => __awaiter(void 0, void 0, void 0, function* () {
            let exitCode = 0;
            try {
                const newExitCode = yield onExit();
                if (newExitCode !== undefined)
                    exitCode = newExitCode;
            }
            catch (error) {
                exitCode = 500;
            }
            process.exit(exitCode);
        }))();
    });
};
