import React from "react";
import ReactDOM from 'react-dom/client';

import {dynaError} from 'dyna-error';

import {
  isLocalhost,
  startConsoleLogger,
} from "utils-library/dist/web";

import {redirectToHttps} from "utils-library/dist/web";

import {reportWebVitals} from "./reportWebVitals";

import {appConfig} from "./sections/application/config/appConfig";

import "./consoleWarnings";
import "./utils/debug-mode";
import "./sections/application/config/sentryConfig";
import "./updateAppHtml";

import {App} from "./sections/application/app/App";

import "./index.css";

startConsoleLogger();

(window as any)._webApp_mainBundleLoaded = true;

redirectToHttps();

if (!isLocalhost) console.info('Client app version: ', appConfig.appVersion);

const rootElement = document.getElementById('root');
if (!rootElement) throw  dynaError({message: `Internal error: Could not find the #root element`});
const root = ReactDOM.createRoot(rootElement);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// To log results (for example: reportWebVitals(console.log))
// Or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
