import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from "react";
import { guid } from "dyna-guid";
import { dynaSwitch } from "dyna-switch";
import Input from "@mui/material/Input";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import { Box } from "../Box";
import { Chips } from "../Chips";
import { HelperText, EHelperTextType, } from "../HelperText";
export var EIInputMultiSelectVariant;
(function (EIInputMultiSelectVariant) {
    EIInputMultiSelectVariant["FILLED"] = "filled";
    EIInputMultiSelectVariant["OUTLINED"] = "outlined";
    EIInputMultiSelectVariant["STANDARD"] = "standard";
})(EIInputMultiSelectVariant || (EIInputMultiSelectVariant = {}));
export var EInputMultiSelectSpacing;
(function (EInputMultiSelectSpacing) {
    EInputMultiSelectSpacing["NONE"] = "none";
    EInputMultiSelectSpacing["SMALL"] = "small";
    EInputMultiSelectSpacing["MEDIUM"] = "medium";
    EInputMultiSelectSpacing["LARGE"] = "large";
})(EInputMultiSelectSpacing || (EInputMultiSelectSpacing = {}));
export var EInputMultiSelectItemType;
(function (EInputMultiSelectItemType) {
    EInputMultiSelectItemType["MENU"] = "MENU";
    EInputMultiSelectItemType["CHIP"] = "CHIP";
})(EInputMultiSelectItemType || (EInputMultiSelectItemType = {}));
export const InputMultiSelect = (props) => {
    const { dataComponentName, sx = {}, variant = EIInputMultiSelectVariant.STANDARD, itemType = EInputMultiSelectItemType.MENU, spacing = EInputMultiSelectSpacing.NONE, disabled, readOnly, hidden, icon, name, noItemLabel = "---", label, ariaLabel, helperLabel = '', options, pickerOptions = 4.5, validationError, value = [], onChange, onClose, } = props;
    const id = useMemo(() => guid(), []);
    const labelId = id + '---label';
    const handleChange = (e) => onChange && onChange(e.target.value);
    const handleClose = (e) => onClose && onClose(e.target.value);
    const MENU_ITEM_HEIGHT = 48;
    const MENU_ITEM_PADDING_TOP = 8;
    return (_jsxs(Box, { sx: Object.assign(Object.assign(Object.assign({}, (hidden
            ? {
                position: 'absolute',
                overflow: 'hidden',
                height: 0,
            }
            : {})), { margin: dynaSwitch(spacing, undefined, {
                [EInputMultiSelectSpacing.NONE]: 0,
                [EInputMultiSelectSpacing.SMALL]: '0 4px 4px 0',
                [EInputMultiSelectSpacing.MEDIUM]: '0 8px 8px 0',
                [EInputMultiSelectSpacing.LARGE]: '0 16px 16px 0',
            }) }), sx), dataComponentName: [dataComponentName, 'InputMultiSelect'], children: [_jsx(InputLabel, { id: labelId, htmlFor: id, children: label }), _jsx(Select, { sx: { width: '100%' }, id: id, "aria-label": ariaLabel, variant: variant, labelId: labelId, name: name, IconComponent: icon ? () => icon : undefined, MenuProps: {
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                    },
                    transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                    },
                    PaperProps: { style: { maxHeight: MENU_ITEM_HEIGHT * pickerOptions + MENU_ITEM_PADDING_TOP } },
                }, disabled: disabled, readOnly: readOnly, multiple: true, input: _jsx(Input, {}), value: value, onChange: handleChange, onClose: handleClose, displayEmpty: true, renderValue: (selectedValues) => {
                    if (!selectedValues.length)
                        return noItemLabel;
                    switch (itemType) {
                        case EInputMultiSelectItemType.MENU:
                            return (selectedValues.map((selectedValue, index, array) => {
                                var _a;
                                return (_jsxs("span", { children: [((_a = options.find(scanOption => scanOption.value === selectedValue)) === null || _a === void 0 ? void 0 : _a.label) || 'err!', index + 1 < array.length ? _jsx("span", { children: ", " }) : null] }, index));
                            }));
                        case EInputMultiSelectItemType.CHIP:
                            return (_jsx(Box, { sx: {
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                }, children: _jsx(Chips, { chips: selectedValues
                                        .map(selectedValue => options.find(scanOption => scanOption.value === selectedValue))
                                        .filter(option => option !== undefined)
                                        .map((option) => ({ label: option.label })) }) }));
                    }
                }, children: options.map((option, index) => (_jsxs(MenuItem, { value: option.value, children: [_jsx(Checkbox, { checked: value.indexOf(option.value) > -1 }), _jsx(ListItemText, { primary: option.label })] }, index))) }), _jsx(HelperText, { children: helperLabel }), _jsx(HelperText, { type: EHelperTextType.ERROR, children: validationError })] }));
};
