import { jsx as _jsx } from "react/jsx-runtime";
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import { Box } from "../Box";
import MoreIcon from '@mui/icons-material/MoreVert';
export var EChipVariant;
(function (EChipVariant) {
    EChipVariant["FILLED"] = "filled";
    EChipVariant["OUTLINE"] = "outlined";
})(EChipVariant || (EChipVariant = {}));
export var EChipColor;
(function (EChipColor) {
    EChipColor["DEFAULT"] = "default";
    EChipColor["PRIMARY"] = "primary";
    EChipColor["SECONDARY"] = "secondary";
    EChipColor["ERROR"] = "error";
    EChipColor["INFO"] = "info";
    EChipColor["SUCCESS"] = "success";
    EChipColor["WARNING"] = "warning";
})(EChipColor || (EChipColor = {}));
export var EChipSize;
(function (EChipSize) {
    EChipSize["SMALL"] = "small";
    EChipSize["MEDIUM"] = "medium";
})(EChipSize || (EChipSize = {}));
export const Chips = (props) => {
    const { dataComponentName, inline, spacing = 1, size = EChipSize.MEDIUM, chips, } = props;
    if (!chips.length)
        return null;
    return (_jsx(Box, { dataComponentName: [dataComponentName, "Chips"], inline: inline, sx: {
            display: 'flex',
            flexWrap: 'wrap',
            paddingTop: theme => theme.spacing(spacing),
            '& > div': {
                marginRight: theme => theme.spacing(spacing),
                marginBottom: theme => theme.spacing(spacing),
            },
        }, children: chips
            .filter(chip => chip.show !== false)
            .map(({ variant = EChipVariant.FILLED, color, avatar: { image = undefined, icon = undefined, text = undefined, } = {}, label, rightIcon, readOnly, onRightIconClick, onChipClick, }, index) => (_jsx(Chip, { sx: { pointerEvents: readOnly ? 'none' : undefined }, variant: variant, color: color, size: size, avatar: (image || icon || text)
                ? (_jsx(Avatar, { src: image, alt: image ? label : undefined, sx: {
                        backgroundColor: '#bdbdbd8f',
                        '& > svg': { width: '80%' },
                    }, children: image
                        ? null
                        : icon || text }))
                : undefined, label: label, deleteIcon: rightIcon || onRightIconClick
                ? rightIcon || _jsx(MoreIcon, {})
                : undefined, onDelete: rightIcon || onRightIconClick
                ? onRightIconClick
                : undefined, onClick: onChipClick }, index))) }));
};
