import { jsx as _jsx } from "react/jsx-runtime";
import { getDataComponentName, } from "../ui-interfaces";
import { Box } from "../Box";
// It needs a parent with position relative to fit to the parent
export const ContainerAbsolut = ({ sx, dataComponentName, show = true, stretch = false, children = null, zIndex, }) => {
    if (!show)
        return null;
    return (_jsx(Box, { dataComponentName: getDataComponentName(dataComponentName, "ContainerAbsolut"), sx: Object.assign(Object.assign(Object.assign({}, sx), { position: "absolute", zIndex }), (stretch && {
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
        })), children: children }));
};
