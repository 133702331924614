import { dynaError, } from "dyna-error";
export const timeoutPromise = ({ promise, timeout, error, }) => new Promise((resolve, reject) => {
    const timeoutId = setTimeout(() => reject(error ||
        dynaError({
            message: "Promise timeout",
            code: 51965664607684,
        })), timeout);
    promise
        .then(result => {
        clearTimeout(timeoutId); // Clear timeout on success
        resolve(result);
    })
        .catch(error => {
        clearTimeout(timeoutId); // Clear timeout on failure
        reject(error);
    });
});
