var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { dynaError } from "dyna-error";
export const isURLReachable = (url) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const response = yield fetch(url);
        if (response.ok) {
            return {
                ok: true,
                status: response.status,
            };
        }
        else {
            return {
                ok: false,
                status: response.status,
            };
        }
    }
    catch (e) {
        const error = dynaError(e);
        return {
            ok: false,
            status: -1,
            networkError: error,
        };
    }
});
